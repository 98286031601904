<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex flex-col items-center p-4 pb-0">
            <!-- Light version -->
            <img class="w-20 dark:hidden" src="images/logo/logo-text.svg" alt="Logo image" />
            <!-- Dark version -->
            <img class="hidden w-20 mb-10 dark:flex" src="images/logo/logo-text-on-dark.svg" alt="Logo image" />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <!-- <form [formGroup]="dateRangeForm" *ngIf="showrange">
                <mat-form-field appearance="outline">
                  <mat-label>Date range</mat-label>
                  <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form> -->
            <div *ngIf="showrange">
                <div class="mb-1">Date Range</div>
                <div>
                    <p-calendar [(ngModel)]="rangeDates" [showIcon]="true" [readonlyInput]="true" selectionMode="range"
                        class="border border-gray-600 rounded-lg p-2 mt-2 shadow-lg" (onSelect)="Onrangeselect($event)"
                        appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
                </div>
            </div>

            <mat-form-field appearance="outline" *ngIf="showdate">
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" [min]="minDate" [max]="maxDate"
                    [matDatepickerFilter]="dateFilter" (dateChange)="onDateChange($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <!-- <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <notifications></notifications> -->
            <!--<button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()"
            >
                <mat-icon
                    [svgIcon]="'heroicons_outline:chat-bubble-left-right'"
                ></mat-icon>
            </button>-->
            <notifications *ngIf=" user && user.role && user.role ==='iocldealer'"></notifications>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
        <router-outlet></router-outlet>
        }
    </div>

    <!-- Footer -->
    <div
        class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden">
        <span class="text-secondary font-medium">DIGITALPETRO &copy; {{ currentYear }}</span>
    </div>
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->