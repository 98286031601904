<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
    <span class="absolute left-0 right-0 top-0 flex h-3 items-center justify-center">
        <span
            class="ml-4 mt-2.5 flex h-4 min-w-4 shrink-0 items-center justify-center rounded-full bg-teal-600 px-1 text-xs font-medium text-indigo-50">
            {{ unreadCount }}
        </span>
    </span>
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>
    <div
        class="fixed inset-0 flex flex-col overflow-hidden shadow-lg sm:static sm:inset-auto sm:w-90 sm:min-w-90 sm:rounded-2xl">
        <!-- Header -->
        <div class="flex shrink-0 items-center bg-primary py-4 pl-6 pr-4 text-on-primary">
            <div class="-ml-1 mr-3 sm:hidden">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="text-current icon-size-5" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Notifications</div>
            <!-- <div class="ml-auto">
                <button class="dark:text-white" mat-icon-button [matTooltip]="'Mark all as read'"
                    [disabled]="unreadCount === 0" (click)="markAllAsRead()">
                    <mat-icon class="text-current icon-size-5" [svgIcon]="'heroicons_solid:envelope-open'"></mat-icon>
                </button>
            </div> -->
        </div>

        <!-- Content -->
        <div class="bg-card relative flex flex-auto flex-col divide-y overflow-y-auto sm:max-h-120">
            @for (
            notification of notifications;
            track trackByFn($index, notification)
            ) {
            <div class="group flex hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                [ngClass]="{ unread: !notification.notification_Read }">
                @if (!notification.link) {
                <div class="flex flex-auto py-5 pl-6">
                    <ng-container>
                        <div
                            class="mr-4 flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gray-100 dark:bg-gray-700">
                            <mat-icon svgIcon="heroicons_mini:star"></mat-icon>
                        </div>
                        <ng-container *ngIf="notification.image">
                            <img class="mr-4 h-8 w-8 shrink-0 overflow-hidden rounded-full object-cover object-center"
                                [src]="notification.image" [alt]="'Notification image'" />
                        </ng-container>
                        <div class="flex flex-auto flex-col">
                            <ng-container *ngIf="notification.interlock_type">
                                <div class="line-clamp-1 font-semibold" [innerHTML]="notification.interlock_type"></div>
                            </ng-container>
                            <ng-container *ngIf="notification.tank_id">
                                Tank ID: {{ notification.tank_id }}
                                <!-- Tank Id:<div class="text-secondary mt-2 text-sm leading-none" [innerHTML]="notification.tank_id">
                                </div> -->
                            </ng-container>
                            <!-- <ng-container *ngIf="notification.userName">
                                <div class="text-secondary mt-2 text-sm leading-none"
                                    [innerHTML]="notification.userName">
                                </div>
                            </ng-container> -->
                            <!-- <ng-container *ngIf="notification.userEmail">
                                <div class="text-secondary mt-2 text-sm leading-none"
                                    [innerHTML]="notification.userEmail">
                                </div>
                            </ng-container> -->
                            <div class="text-secondary mt-2 text-sm leading-none">
                                InterLock Date: {{ notification.datetime |utcToLocale }}
                            </div>
                        </div>
                    </ng-container>
                </div>
                }
                <!-- <div class="relative my-5 ml-2 mr-6 flex flex-col">
                    <button class="h-6 min-h-6 w-6" mat-icon-button
                    (click)="onNotificationClick(notification)" [matTooltip]="'Remove'">
                        <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                    </button>
                </div> -->
            </div>
            }
            <!-- No notifications -->
            @if (!notifications || !notifications.length) {
            <div class="flex flex-auto flex-col items-center justify-center px-8 py-12 sm:justify-start">
                <div
                    class="flex h-14 w-14 flex-0 items-center justify-center rounded-full bg-primary-100 dark:bg-primary-600">
                    <mat-icon class="text-primary-700 dark:text-primary-50"
                        [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                </div>
                <div class="mt-5 text-2xl font-semibold tracking-tight">
                    No notifications
                </div>
                <div class="text-secondary mt-1 w-full max-w-60 text-center text-md">
                    When you have notifications, they will appear here.
                </div>
            </div>
            }
        </div>
    </div>
</ng-template>